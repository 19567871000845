import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Container from "react-bootstrap/Container"
import ReactMarkdown from "react-markdown"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Cite from "citation-js"

import "../../fragments"
import bibtex from "raw-loader!./publications.bib"
import Features from "../../components/features/features"
import BibItemOptions from "../../components/bibliography/options"
import News from "../../components/news"
import { getRawEntries } from "../../components/bibliography/utils"

const intro = `
  Why do humans make music, and how do they do it?
  Why are we a musical species?
  These are the questions that motivate my research.
  I use computational methods to study how musical
  traditions differ. Mapping the diversity of musics across the globe, 
  is a key step in understanding the cultural evolution of musics.
`

const bio = `
  I am a PhD student with Jelle Zuidema and John Ashley Burgoyne at the 
  [Institute for Logic, Language and Computation](http://www.illc.uva.nl/),
  [University of Amsterdam](https://www.uva.nl/)
  and member of the [clclab](http://illc.uva.nl/clclab/) and the 
  [Music Cognition Group](https://www.mcg.uva.nl/).
  Before that I finished the bachelor 
  [Bèta-gamma](https://www.uva.nl/programmas/bachelors/beta-gamma/beta-gamma.html) 
  and the [Master of Logic](https://msclogic.illc.uva.nl/) at the 
  same university, both with a major in mathematics.
  `

function BibItem({ publication, ...props }) {
  if(publication.bibItem.data.length === 0)  return null;
  try { 
    return (
      <div className="bib-item" {...props}>
        <div dangerouslySetInnerHTML={{__html: 
          publication.bibItem.format('bibliography', {
            format: "html",
            template: "apa"
          })
        }}  />
        <BibItemOptions project={publication} />
      </div>
    )
  } catch {
    return (
      <div className="bib-item" {...props}>
        An error occurred while generating a reference for {publication.bibtexKey}.
      </div>
    )
  }
}

function Bibliography({ publications }) {
  publications = publications.filter(pub => (pub.bibItem !== null))
  return (
    <div className="bibliography text-book">
      {publications.map(pub => 
        <BibItem publication={pub} key={pub.id} />)}
    </div>
  )
}

export default function ResearchPage({ data }) {
  // Add bibItem fields to all projects
  const bibliography = new Cite(bibtex).sort()
  bibliography.data = bibliography.data.reverse()
  const ids = bibliography.getIds();
  const publications = data.publications.nodes.map(pub => {
    const index = ids.indexOf(pub.bibtexKey)
    pub.bibItem = (index !== -1) 
      ? new Cite(bibliography.data[index])
      : null;
    return pub
  })

  // Featured publications
  const features = publications.filter(pub => pub.featured)
  return (
    <Layout className="page-research">
      <Container>
        <Row className="mb-5">
          <Col md={8} style={{maxWidth: 1200}}>
            <ReactMarkdown children={intro} className="lead text-primary mb-5" />
            <ReactMarkdown children={bio} className="text-book" />
          </Col>
        </Row>

        <Row>
          <Col className="mb-5 pe-5">
            <h2 className="striped-header mb-4"><span>Updates</span></h2>
            <News items={data.updates.nodes} columnsCountBreakPoints={{600: 2, 900: 3}}/>
          </Col>
        </Row>

        <Row className="mb-5">
          <h2 className="striped-header mb-5"><span>Selected work</span></h2>
          <Features projects={features} bibliography={bibliography.data} />
        </Row>
        <Row>
          <Col>
            <h2 className="striped-header mb-5"><span>All output</span></h2>
            <Bibliography publications={publications} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ResearchQuery {
    updates: allNews(
      sort: {fields: date, order: DESC}, 
      limit: 6
      filter: {tags: {in: ["research"]}}
    ) {
      nodes {
        date
        message
        tags
      }
    }

    publications: allResearch(
      filter: {
        status: {eq: published},
        category: {in: ["publication", "poster", "talk"]}
      },
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        ...researchProperties
      }
    }
  }
`

